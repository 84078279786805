import axios from 'axios';
import Const from '../common/constants';
//Create API Instance
let instance = axios.create({
  baseURL: Const.API.Url,
  headers: { 'Content-Type': 'application/json' }
});

instance.interceptors.request.use(
    //Use Static Bearer Token for Open APIs
    function (config) {
      config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Imp5b3RpQHF1b2xhbS5jb20iLCJpYXQiOjE2MDEyNzgyMzcsImV4cCI6MTYwMTM2NDYzN30.b721Mo7B-4uqQ74cWmgqZAD9hYxUA2dSIxEkPkw-mDs`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

export default instance;
