import React from 'react';
import logo from '../../assets/img/FullLogo-New.png';

// Footer component globally attached in the layout component 
const Footer = () => {
    return (
        <footer className="page-footer py-0 text-center bg-white font-small">
            <div className="footer-copyright bg-transparent my-0 text-white d-flex align-items-center justify-content-end">
                <a href="https://www.raisingamathematician.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 1rem' }}>
                    <img alt="logo" src={logo} className="img-fluid d-inline mx-2" style={{ width: '160px' }} />
                </a>
            </div>
        </footer>
    );
};

export default Footer;