import API from '../services/api-config.service';
import API_STATIC from '../services/api-static-config.service';

export function getCities(){
    return API_STATIC({
        method: 'GET',
        url: `/cities`,
      });
}

export function getGrades(){
    return API_STATIC({
        method: 'GET',
        url: `/grades`,
      });
}
export function getStudents(){
  return API({
      method: 'GET',
      url: `/student/all`,
    });
}

export function getStates(){
  return API_STATIC({
      method: 'GET',
      url: `/states`,
    });
}

export function getCountries(){
  return API_STATIC({
      method: 'GET',
      url: `/countries`,
    });
}

export function getBoards(){
  return API_STATIC({
      method: 'GET',
      url: `/boards`,
    });
}

export function getInstitutes(){
  return API_STATIC({
      method: 'GET',
      url: `/institute`,
    });
}

export function getCollegeDegrees(){
  return API_STATIC({
      method: 'GET',
      url: `/collageDegree`,
    });
}

export function getAllStudents(){
	return API({
		method: 'GET',
		url: `/student/studentNames`,
	  });
  }

  export function getAllScrenarios(){
    return API({
      method: 'GET',
      url: `/screnarios`,
      });
    }
  
 export function getScrenariosById(scenarioId){
      return API({
        method: 'GET',
        url: `/screnarios/${scenarioId}`,
        });
      }