import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import UserStore from '../common/user-store';
import Layout from '../shared/layout';
import Async from 'react-code-splitting';

//Routes redirection after logging in 
const LoginRoute = ({ component: Component, pageType, ...rest }) => (
    <Route {...rest} render={props => (
        UserStore.loggedin() ? (
            <Redirect to={{
                pathname: '/dashboard',
                state: { from: props.location }
            }} />
        ) :
            (
                <Layout>
                    <Component pageType={pageType} {...props} />
                </Layout>
            )
    )} />
);

//Routes accessible only after login
const PrivateRoute = ({ component: Component, pageType, ...rest }) => (
    <Route {...rest} render={props => (
        UserStore.loggedin() ? (
            <Layout>
                <Component pageType={pageType} {...props} />
            </Layout>
        ) : (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }} />
            )
    )} />
);

//Open Routes not needing login 
const PublicRoute = ({ component: Component, pageType, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} pageType={pageType} />
        </Layout>
    )} />
);

const Login = props => (
    <Async load={import('../views/AuthScreens/Login')} componentProps={props} />
);
const ForgotPassword = props => (
    <Async load={import('../views/AuthScreens/ForgotPassword')} componentProps={props} />
);
const ResetPassword = props => (
    <Async load={import('../views/AuthScreens/ResetPassword')} componentProps={props} />
);
const StudentRegistration = props => (
    <Async load={import('../views/AuthScreens/StudentRegistration')} componentProps={props} />
);
const CourseDetails = props => (
    <Async load={import('../views/CourseDetails')} componentProps={props} />
);

const Dashboard = props => (
    <Async load={import('../views/Dashboard')} componentProps={props} />
);

const AdminDashboard = props => (
    <Async load={import('../views/AdminScreens/Dashboard')} componentProps={props} />
);
const AdminCourses = props => (
    <Async load={import('../views/AdminScreens/Courses')} componentProps={props} />
);
const AdminStudentProfile = props => (
    <Async load={import('../views/AdminScreens/StudentProfiles')} componentProps={props} />
)
const AdminRegistrationList = props => (
    <Async load={import('../views/AdminScreens/RegistrationList')} componentProps={props} />
);
const AdminEmailList = props => (
    <Async load={import('../views/AdminScreens/EmailList')} componentProps={props} />
);

const AdminAddEmailTemplate = props => (
    <Async load={import('../views/AdminScreens/AddEmailTemplate')} componentProps={props} />
);

const AdminAddCertificateTemplate = props => (
    <Async load={import('../views/AdminScreens/AddCertificateTemplate')} componentProps={props} />
);

const AdminAddCourse = props => (
    <Async load={import('../views/AdminScreens/AddCourse')} componentProps={props} />
);
const AdminStudentEdit = props => (
    <Async load={import('../views/AdminScreens/EditStudentProfile')} componentProps={props} />
);
const AdminFieldList = props => (
    <Async load={import('../views/AdminScreens/FieldsList')} componentProps={props} />
);
const AdminFormList = props => (
    <Async load={import('../views/AdminScreens/FormList')} componentProps={props} />
);

const AdminPaymentList = props => (
    <Async load={import('../views/AdminScreens/PaymentList')} componentProps={props} />
);

const StudentAllCourses = props => (
    <Async load={import('../views/StudentScreens/MyCourses/')} componentProps={props} />
);

const StudentPendingRegistrations = props => (
    <Async load={import('../views/StudentScreens/PendingRegistrations/')} componentProps={props} />
);

const StudentMyCertificates = props => (
    <Async load={import('../views/StudentScreens/MyCertificates')} componentProps={props} />
);

const StudentEditCertificate = props => (
    <Async load={import('../views/StudentScreens/EditCertificate')} componentProps={props} />
);

const AdminChangePassword = props => (
    <Async load={import('../views/AuthScreens/ChangePassword')} componentProps={props} />
);

const AdminTeacherRecommendation = props => (
    <Async load={import('../views/AdminScreens/TeacherRecommendation')} componentProps={props} />
);

const StudentCourseRegistration = props => (
    <Async load={import('../views/StudentScreens/CourseRegistration')} componentProps={props} />
);
const AdminStudentReports = props => (
    <Async load={import('../views/AdminScreens/Reports/')} componentProps={props} />
);
const AdminCourseRegistrationDetails = props => (
    <Async load={import('../views/AdminScreens/CourseRegistrationDetails')} componentProps={props} />
);

const AdminCertificateListing = props => (
    <Async load={import('../views/AdminScreens/CertificateList')} componentProps={props} />
);
const AdminBulkRegistration = props => (
    <Async load={import('../views/AdminScreens/BulkRegistration')} componentProps={props} />
);

const routes = (
    <Switch>
        <LoginRoute exact path="/login" component={Login} pageType="Home" />
        <PublicRoute exact path="/forgot-password" component={ForgotPassword} pageType="Home" />
        <PublicRoute exact path="/reset-password" component={ResetPassword} pageType="Home" />
        <PublicRoute exact path="/verify-email" component={ResetPassword} pageType="Home" />
        <PublicRoute exact path="/student-registration" component={StudentRegistration} pageType="Home" />
		<PrivateRoute exact path="/dashboard/:courseType" component={Dashboard} pageType="Dashboard" />
        <PrivateRoute exact path="/dashboard" component={Dashboard} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/dashboard' component={AdminDashboard} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/student-profiles/edit/:studentId' component={AdminStudentEdit} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/student-profiles' component={AdminStudentProfile} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/bulk-registrations' component={AdminBulkRegistration} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/registrations' component={AdminRegistrationList} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/emailers/edit/:templateId' component={AdminAddEmailTemplate} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/emailers/add' component={AdminAddEmailTemplate} pageType="Dashboard" />
		<PrivateRoute exact path='/admin/certificates/add' component={AdminAddCertificateTemplate} pageType="Dashboard" />
		<PrivateRoute exact path='/admin/certificates/edit/:certificateId' component={AdminAddCertificateTemplate} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/emailers' component={AdminEmailList} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/courses/edit/:courseId' component={AdminAddCourse} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/courses/add' component={AdminAddCourse} pageType="Dashboard" />
        <PrivateRoute exact path="/admin/courses" component={AdminCourses} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/course/edit/:courseId' component={AdminAddCourse} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/courses/add-course' component={AdminAddCourse} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/student-profile/edit/:studentId' component={AdminStudentEdit} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/field-list' component={AdminFieldList} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/form-list' component={AdminFormList} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/payments' component={AdminPaymentList} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/change-password' component={AdminChangePassword} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/reports' component={AdminStudentReports} pageType="Dashboard" />
        <PrivateRoute exact path='/admin/course-registration/view/:studentId/:regId' component={StudentCourseRegistration} pageType="Dashboard" />
		<PublicRoute exact path='/admin/course-registration-details/:studentId/:regId' component={AdminCourseRegistrationDetails} pageType="Dashboard" />
        <PrivateRoute exact path="/student/course/details/:courseId" component={CourseDetails} pageType="Dashboard" />
        <PrivateRoute exact path='/student/my-courses' component={StudentAllCourses} pageType="Dashboard" />
        <PrivateRoute exact path='/student/pending-registrations' component={StudentPendingRegistrations} pageType="Dashboard" />
        <PrivateRoute exact path='/student/my-profile/edit/:studentId' component={AdminStudentEdit} pageType="Dashboard" />
        <PrivateRoute exact path='/student/my-certificates' component={StudentMyCertificates} pageType="Dashboard" />
        <PrivateRoute exact path='/student/my-certificates/edit/:certificateId' component={StudentEditCertificate} pageType="Dashboard" />
        <PrivateRoute exact path='/student/my-certificates/add-certificate' component={StudentEditCertificate} pageType="Dashboard" />
        <PrivateRoute exact path='/student/course-registration/:studentId/:regId' component={StudentCourseRegistration} pageType="Dashboard" />
		<PrivateRoute exact path='/admin/certificates' component={AdminCertificateListing} pageType="Dashboard" />
        <PublicRoute exact path='/teacher-recommendation-form' component={AdminTeacherRecommendation} pageType="Home" />
        <Redirect exact from="/" to="/login" />
        <Redirect to='/' />
    </Switch>
);

export default routes;