import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { render } from 'react-dom';
import routes from './routes/routes';
import './assets/css/style.css';
import './assets/css/editor.css';
import './assets/css/loader.scss';
import './assets/css/bootstrap.min.css';
import './assets/css/mdb.min.css';
import '../node_modules/react-notifications/src/notifications.scss';
import '../node_modules/react-widgets/lib/scss/react-widgets.scss';
import './assets/css/dashboard.css';
import './assets/css/adminDashboard.css';
import './assets/css/coursedetails.css';
import './assets/css/listingview.css';
import './assets/css/studentRegistration.css';
import './assets/css/courseList.css';
import './assets/css/studentProfiles.css';
import './assets/css/studentCertificate.css';
import './assets/css/adminProfile.css';
import './assets/css/adminCourseRegistration.css';
import './assets/css/adminReports.css';
import './assets/css/certificateTemplate.css';
// import * as serviceWorker from './serviceWorker';

render(<Router>{routes}</Router>, document.getElementById('root'));

// serviceWorker.unregister();
