class User {
  //Set user variable to the Local Storage
  set(data) {
    var user = JSON.stringify(data);
    window.localStorage.setItem("user", user);
  }

  //get values from user variable stored in the Local Storage
  get(prop) {
    let user = window.localStorage.getItem("user");
    if (user !== null) return JSON.parse(user)[prop];
    else return null;
  }

  //update user variable stored in the Local Storage
  let(key, value) {
    var user = window.localStorage.getItem("user");
    if (user !== null) {
      var store = JSON.parse(user);
      store[key] = value;
      window.localStorage.setItem("user", JSON.stringify(store));
    }
  }

  //check if user is logged in to the system by checking the presence of user variable in local storage
  loggedin() {
    let user = window.localStorage.getItem("user");
    if (user !== null) return true;
    else return false;
  }

  //Clear user variable in Local Storage which will redirect to login page route
  logout() {
    window.localStorage.clear();
    window.location.href = "/";
  }
}

let userStore = (window.UserStore = new User());
export default userStore;
