import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/RamLogo.png';
import userStore from '../../common/user-store';
import Const from '../../common/constants';

// Dashboard Sidebar with navigation options
class Sidemenu extends React.Component {
	static get defaultProps() {
		return {
			pageTitle: null
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			location: '',
			ownClinics: null,
			showSubMenu: false,
			role: userStore.get('Role'),
			studentId: ''
		}

	}

	componentDidMount() {
		let { location } = this.state;
		location = window.location.href;
		this.setState({ location, studentId: userStore.get('studentId') && userStore.get('studentId') });
	}

	toggleSidebar = () => {
		let { toggleDrawer } = this.props;
		toggleDrawer();
	};

	render() {
		let location = window.location.href;
		let { studentId } = this.state;
		location = location.toString();

		return ([
			<div className="sidebar-wrapper">
				<div className="logo">
					<a href="https://mailchinthu.wixsite.com/ramf" target="_blank" className="simple-text" rel="noopener noreferrer">
						<img src={logo} className="w-50" alt="Sidemenu Banner" />
					</a>
				</div>
				{/* Navigation Options for Student Login */}
				{userStore.get('roleId') === Const.ROLE.STUDENT &&
					<ul className="nav">
						<li className={`nav-item ${location.includes('/dashboard') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/dashboard">
								<i className="la px-2 la-lg la-cube"></i>
								<p className="mb-0">Dashboard</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/student/my-profile') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to={`/student/my-profile/edit/${studentId}`}>
								<i className="la px-2 la-lg la-user-circle"></i>
								<p className="mb-0">My Profile</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/student/my-courses') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/student/my-courses">
								<i className="la px-2 la-lg la-book"></i>
								<p className="mb-0">My Courses</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/student/my-certificates') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/student/my-certificates">
								<i className="la px-2 la-lg la-certificate"></i>
								<p className="mb-0">My Documents</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/student/pending-registrations') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/student/pending-registrations">
								<i className="la px-2 la-lg la-pen-square"></i>
								<p className="mb-0">Pending Registrations</p>
							</Link>
						</li>
					</ul>
				}

				{/* Navigation Options for Admin Login */}
				{userStore.get('roleId') === Const.ROLE.ADMIN &&
					<ul className="nav">
						<li className={`nav-item ${location.includes('/admin/dashboard') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/dashboard">
								<i className="la px-2 la-lg la-cube"></i>
								<p className="mb-0">Dashboard</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/registrations') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/registrations">
								<i className="la px-2 la-lg la-user-circle"></i>
								<p className="mb-0">Registrations</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/courses') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/courses">
								<i className="la px-2 la-lg la-book"></i>
								<p className="mb-0">Courses</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/student-profiles') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/student-profiles">
								<i className="la px-2 la-lg la-user-circle"></i>
								<p className="mb-0">Student Profiles</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/form-list') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/form-list">
								<i className="la px-2 la-lg la-wpforms"></i>
								<p className="mb-0">Form Templates</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/emailers') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/emailers">
								<i className="la px-2 la-lg la-envelope"></i>
								<p className="mb-0">Mailers</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/certificates') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/certificates">
								<i className="la px-2 la-lg la-certificate"></i>
								<p className="mb-0">Certificates</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/reports') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/reports">
								<i className="la px-2 la-lg la-cube"></i>
								<p className="mb-0">Reports</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/payments') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/payments">
								<i className="la px-2 la-lg la-comment-dollar"></i>
								<p className="mb-0">Donations</p>
							</Link>
						</li>
						<li className={`nav-item ${location.includes('/admin/bulk-registrations') ? " active" : ""}`}>
							<Link className="nav-link px-2 mx-2 d-flex flex-row align-items-center" to="/admin/bulk-registrations">
								<i className="la px-2 la-lg la-file-upload"></i>
								<p className="mb-0">Bulk Upload</p>
							</Link>
						</li>
					</ul>
				}
			</div>,
			<div className="sidebar-background"></div>
		])
	}
}

export default Sidemenu;