import React from "react";
import userStore from "../../common/user-store";
import { Link } from "react-router-dom";
import logoutIcon from "../../assets/icons/icn-logout.svg";
import hamburger from "../../assets/icons/icn-hamburger-menu.svg";
import logo from "../../assets/img/FullLogo-New.png";

//Dashboard Header with change password and logout options
class Header extends React.Component {
  static get defaultProps() {
    return {
      pageTitle: null
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      profilePicture: "",
      customer: userStore.get("Customer"),
      username: `${userStore.get("firstName")} ${userStore.get("lastName")}`,
      role: userStore.get("Role"),
      location: "",
      dropdownToggle: false
    };
  }

  componentDidMount() {
    this.setState({ location: this.props.props.location.pathname });
  }

  toggleSidebar = () => {
    let { toggleDrawer } = this.props;
    toggleDrawer();
  };

  render() {
    let { username} = this.state;
    return [
      <nav className="navbar navbar-expand-lg main-navbar dashboard-header justify-content-center py-0 px-1 px-md-3">
        <div className="mr-auto">
          <ul className="navbar-nav py-2">
            <li>
              <a
                href="#!"
                data-toggle="sidebar"
                className=" pl-0 hamburger nav-link nav-link-lg py-0"
                onClick={this.toggleSidebar}
              >
                <img
                  src={hamburger}
                  alt="--"
                  className="ml-2 mr-3 hamburger__icon"
                />
              </a>
            </li>
          </ul>
          <div className=" form-inline mr-auto horizontal" id="headerMenuCollapse">
            <div className="d-none d-lg-block" />
          </div>
        </div>
        <a className="d-none d-md-block text-center position-absolute px-md-4" href="#!">
          <img
            src={logo}
            className="header-brand-img my-0 mx-auto"
            alt="RAM Foundation"
            style={{ height: "4.5rem" }}
          />
        </a>
        <ul className="navbar-nav navbar-right pt-0">
          <li className="dropdown dropdown-list-toggle d-block">
            <a
              href="#!"
              data-toggle="dropdown"
              className="user-dropdown nav-link dropdown-toggle nav-link-lg p-0 font-weight-bold"
              onClick={() => {
                this.setState({ dropdownToggle: !this.state.dropdownToggle });
              }}
            >
              {username}
            </a>
            <div
              className={`dropdown-menu dropdown-menu-right rounded-0`}
            >
              <Link to={"/admin/change-password"} className="dropdown-item has-icon">
                <i className="las la-key la-lg mr-3 ml-2 color-grey"></i>
                <span>Change Password</span>
              </Link>
              <Link
                to={"/"}
                className="dropdown-item has-icon"
                onClick={() => {
                  userStore.logout();
                }}
              >
                <img
                  src={logoutIcon}
                  alt="--"
                  className="ml-2 mr-3 navbar-dropdown__icon"
                />
                <span>Logout</span>
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    ];
  }
}

export default Header;
