import React from 'react';
import HomeHeader from '../components/shared/home-header';
import Header from '../components/shared/header';
import Footer from '../components/shared/footer';
import UserStore from '../common/user-store';
import { NotificationContainer } from 'react-notifications';
import Sidemenu from "../components/sidemenu/sidemenu";
import { getWindowDimensions } from '../common/helper';

class Layout extends React.Component {
    constructor(props) {
        super(props);

        let { width } = getWindowDimensions();
        this.state = {
            isDrawerOpen: width > 991,
            width: width
        };
    }

    componentDidMount(){
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    toggleDrawer = isDrawerOpen => {
        let { width } = this.state;
        if (width < 1024) {
            if (typeof isDrawerOpen === 'undefined') {
                isDrawerOpen = !this.state.isDrawerOpen;
            }
            this.setState({ isDrawerOpen: isDrawerOpen });
        }
    };

    toggleDrawerViaHeader = isDrawerOpen => {
        if (typeof isDrawerOpen === 'undefined') {
            isDrawerOpen = !this.state.isDrawerOpen;
        }
        this.setState({ isDrawerOpen: isDrawerOpen });
    };

    render() {
        let { children } = this.props;

        let { isDrawerOpen } = this.state;
        return (
            <React.Fragment>
                <main className="page-wrapper page-layout page-layout-dashboard position-relative">
                    {children.props.pageType === "Home" ?
                        <div className="body-overlay">
                        </div>
                        :
                        <div className="body-overlay dashboard-overlay">
                        </div>
                    }
                    <div className={`drawer-overlay ${isDrawerOpen ? 'show-drawer' : ''}`} onClick={() => this.toggleDrawer(false)} />
                    {children.props.pageType === "Home" ?
                        <header className={` page-header ${isDrawerOpen ? 'show-drawer' : ''}`}>
                            <HomeHeader />
                        </header>
                        :
                        <header className={`page-header dashboard-header ${isDrawerOpen ? 'show-drawer' : ''}`}>
                            <Header props={children.props} toggleDrawer={this.toggleDrawerViaHeader} />
                        </header>
                    }
                    {children.props.pageType === "Home" ?
                        <aside className={`page-sidebar ${isDrawerOpen ? 'show-drawer' : ''}`}>
                        </aside>
                        :
                        <div className={`sidebar ${isDrawerOpen ? 'show-drawer' : ''}`} data-image="../assets/img/sidebar-5.jpg">
                            <Sidemenu toggleDrawer={this.toggleDrawer} path={children.props.location.pathname} />
                        </div>
                    }
                    {children.props.pageType === "Home" ?
                        <div className={`page-content component-loading ${isDrawerOpen ? 'show-drawer' : ''}`}>
                            {children}
                        </div>
                        :
                        <div className={` page-content component-loading dashboard-content position-relative ${isDrawerOpen ? 'show-drawer' : ''} ${UserStore.get('is_active') ? "app-content pt-md-4" : "app-content pt-md-4"}`}>
                            {children}
                        </div>
                    }
                    {children.props.pageType === "Home" ?
                        <div>

                        </div>
                        :
                        <div className={`my-0 page-footer-div z-depth-1 ${isDrawerOpen ? 'show-drawer' : ''}`}>
                            <Footer />
                        </div>
                    }
                </main>

                <NotificationContainer className="notification-box" />
            </React.Fragment>
        );
    }
}

// const Layout = ({ children }) => (
//     <div id="wrapper">


//         <div className="component-loading">

//             {children}
//         </div>
//         {/* <Footer /> */}


//     </div>
// );

export default Layout;