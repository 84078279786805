import React from "react";
import UserStore from "../../common/user-store";
import logo from "../../assets/img/FullLogo-New.png";

//Home Header (when not logged in)  
class HomeHeader extends React.Component {
  static get defaultProps() {
    return {
      pageTitle: null
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      profilePicture: "",
      username: UserStore.get("Username")
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <nav
          className="navbar navbar-home navbar-expand-lg main-navbar bg-white text-dark p-0 px-2"
          style={{ justifyContent: "start" }}
        >
          <a
            className="header-brand d-md-block text-left p-0 px-md-2"
            style={{ height: "100%" }}
            href="#!"
          >
            <img
              src={logo}
              className="header-brand-img my-0 mx-auto"
              alt="RAM Foundation"
              style={{ height: "4.5rem" }}
            />
          </a>
        </nav>
      </div>
    );
  }
}

export default HomeHeader;
